import * as _getTimeStamp2 from "@uppy/utils/lib/getTimeStamp";

var _getTimeStamp = "default" in _getTimeStamp2 ? _getTimeStamp2.default : _getTimeStamp2;

var exports = {};

/* eslint-disable no-console */
const getTimeStamp = _getTimeStamp; // Swallow all logs, except errors.
// default if logger is not set or debug: false

const justErrorsLogger = {
  debug: () => {},
  warn: () => {},
  error: (...args) => console.error(`[Uppy] [${getTimeStamp()}]`, ...args)
}; // Print logs to console with namespace + timestamp,
// set by logger: Uppy.debugLogger or debug: true

const debugLogger = {
  debug: (...args) => console.debug(`[Uppy] [${getTimeStamp()}]`, ...args),
  warn: (...args) => console.warn(`[Uppy] [${getTimeStamp()}]`, ...args),
  error: (...args) => console.error(`[Uppy] [${getTimeStamp()}]`, ...args)
};
exports = {
  justErrorsLogger,
  debugLogger
};
export default exports;